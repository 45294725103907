import { motion } from 'framer-motion';
import styled from 'styled-components';

import { pxToRems, setVh } from 'utils/styles/mixins';

export const Wrapper = styled(motion.div)`
  display: grid;
  place-items: center;
  min-height: ${setVh(100)};
  padding-inline: ${pxToRems(21)};
`;

export const Content = styled.div`
  max-width: 987px;
  justify-self: center;
  align-self: center;
  width: 100%;
  margin-block: 34px 55px;
`;

export const Title = styled.div`
  margin-block: 13px 34px;
  h1 {
    max-width: 610px;
  }
`;
